body {
	--windowRadiusLarge: 15px;
	--windowRadiusLarge-inner: calc(var(--windowRadiusLarge) - 1px);
	--windowRadiusSmall: 15px;
	--windowRadiusSmall-inner: calc(var(--windowRadiusSmall) - 1px);
}

// useage: @incldue window-outer;
@mixin window-outer {
    padding: 1px;
    background: var(--ui-window-border-background);
}

@mixin windowRadius-default() {
	border-radius: var(--windowRadiusSmall);
    .uiWindow-content {
    	border-radius: var(--windowRadiusSmall);
    	.uiWindow-inner {
    		border-radius: var(--windowRadiusSmall-inner);
    	}
    }
}

@mixin windowRadius-large() {
	border-radius: var(--windowRadiusLarge);
    .uiWindow-content {
    	border-radius: var(--windowRadiusLarge);
    	.uiWindow-inner {
    		border-radius: var(--windowRadiusLarge-inner);
    	}
    }
}

@mixin windowRadius-none() {
	border-radius: 0;
    .uiWindow-content {
    	border-radius: 0;
    	.uiWindow-inner {
    		border-radius: 0;
    	}
    }
}