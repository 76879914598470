/* PROCESSING ANIMATION */
div.caution-processing {
    --processing-opacity: 0.08;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    transition: opacity 0.3s ease;
    background-size: 60px 60px;
    background-image: linear-gradient( 
        45deg, 
        rgba(255, 255, 255, var(--processing-opacity)) 25%,
        transparent 25%, 
        transparent 50%, 
        rgba(255, 255, 255, var(--processing-opacity)) 50%, 
        rgba(255, 255, 255, var(--processing-opacity)) 75%, 
        transparent 75%, 
        transparent);
    animation: barberpole 1.2s linear infinite;
}

div.caution-processing.default-border-radius {
    border-radius: var(--ui-button-border-radius);
}

// BACKROUND IS RED
div.caution-processing.accent {
    --processing-dark-opacity: 0.14;
    background-image: linear-gradient( 
        45deg, 
        rgba(255, 255, 255, var(--processing-dark-opacity)) 25%,
        transparent 25%, 
        transparent 50%, 
        rgba(255, 255, 255, var(--processing-dark-opacity)) 50%, 
        rgba(255, 255, 255, var(--processing-dark-opacity)) 75%, 
        transparent 75%, 
        transparent);
}

// BACKGROUND IS BLACK
div.caution-processing.publishing {
    --processing-dark-opacity: 0.08;
    background-image: linear-gradient( 
        45deg, 
        rgba(255, 255, 255, var(--processing-dark-opacity)) 25%,
        transparent 25%, 
        transparent 50%, 
        rgba(255, 255, 255, var(--processing-dark-opacity)) 50%, 
        rgba(255, 255, 255, var(--processing-dark-opacity)) 75%, 
        transparent 75%, 
        transparent);
}

// BACKGROUND IS GREY
div.caution-processing.dark {
    --processing-dark-opacity: 0.08;
    background-image: linear-gradient( 
        45deg, 
        rgba(0, 0, 0, var(--processing-dark-opacity)) 25%,
        transparent 25%, 
        transparent 50%, 
        rgba(0, 0, 0, var(--processing-dark-opacity)) 50%, 
        rgba(0, 0, 0, var(--processing-dark-opacity)) 75%, 
        transparent 75%, 
        transparent);
}

// BACKGROUND IS WHITE
div.caution-processing.white-bg {
    --processing-dark-opacity: 0.03;
    background-image: linear-gradient( 
        45deg, 
        rgba(0, 0, 0, var(--processing-dark-opacity)) 25%,
        transparent 25%, 
        transparent 50%, 
        rgba(0, 0, 0, var(--processing-dark-opacity)) 50%, 
        rgba(0, 0, 0, var(--processing-dark-opacity)) 75%, 
        transparent 75%, 
        transparent);
}

.dark {
    div.caution-processing {
        --processing-dark-opacity: 0.055;
        background-image: linear-gradient( 
            45deg, 
            rgba(0, 0, 0, var(--processing-dark-opacity)) 25%,
            transparent 25%, 
            transparent 50%, 
            rgba(0, 0, 0, var(--processing-dark-opacity)) 50%, 
            rgba(0, 0, 0, var(--processing-dark-opacity)) 75%, 
            transparent 75%, 
            transparent);
    }

    div.caution-processing.publishing {
        --processing-dark-opacity: 0.055;
        background-image: linear-gradient( 
            45deg, 
            rgba(0, 0, 0, var(--processing-dark-opacity)) 25%,
            transparent 25%, 
            transparent 50%, 
            rgba(0, 0, 0, var(--processing-dark-opacity)) 50%, 
            rgba(0, 0, 0, var(--processing-dark-opacity)) 75%, 
            transparent 75%, 
            transparent);
    }

    div.caution-processing.white-bg {
        --processing-opacity: 0.04;
        background-image: linear-gradient( 
            45deg, 
            rgba(255, 255, 255, var(--processing-opacity)) 25%,
            transparent 25%, 
            transparent 50%, 
            rgba(255, 255, 255, var(--processing-opacity)) 50%, 
            rgba(255, 255, 255, var(--processing-opacity)) 75%, 
            transparent 75%, 
            transparent);
    }

    div.caution-processing.accent {
        --processing-dark-opacity: 0.075;
        background-image: linear-gradient( 
            45deg, 
            rgba(0, 0, 0, var(--processing-dark-opacity)) 25%,
            transparent 25%, 
            transparent 50%, 
            rgba(0, 0, 0, var(--processing-dark-opacity)) 50%, 
            rgba(0, 0, 0, var(--processing-dark-opacity)) 75%, 
            transparent 75%, 
            transparent);
    }

    // exception for C2 site delete slide confirm
    .slide-confirm-slider div.caution-processing.accent {
        --processing-dark-opacity: 0.14;
        background-image: linear-gradient( 
            45deg, 
            rgba(255, 255, 255, var(--processing-dark-opacity)) 25%,
            transparent 25%, 
            transparent 50%, 
            rgba(255, 255, 255, var(--processing-dark-opacity)) 50%, 
            rgba(255, 255, 255, var(--processing-dark-opacity)) 75%, 
            transparent 75%, 
            transparent);
    }
}

@keyframes barberpole {
  from { background-position: 120px 60px; }
  to   { background-position: 0 0; }
}