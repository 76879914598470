body {
    /* Global interface measurements */
    --height-row: 40px;
    --height-row-numeric: 40;

    /* Fonts */
    --fontFamily-default: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    --fontFamily-icons: "Icons", sans-serif;
    --fontFamily-mono: 'Monument Grotesk Mono', monospace;
    --fontFamily-SFMono: 'SF Mono', SFMono-Regular, ui-monospace, 'DejaVu Sans Mono', Menlo, Consolas;
    // 'SF Mono', SFMono-Regular, ui-monospace, 'DejaVu Sans Mono', Menlo, Consolas,

    /* Font Sizes & Line Heights*/
    --fontSize-default: 14.5px;
    --fontSize-secondary: 13.5px;
    --fontSize-small: 12px;
    --lineHeight-default: 1.65;
    --fontSize-large: 22px;
    --fontSize-header: 17px;

    &.mobile {
        --fontSize-default: 16px;
        --lineHeight-default: 1.5;
        --fontSize-header: 18px;
    }

    /* Text Colors */
    --color-interface-element-default: var(--base-color-text-default);
    --color-interface-element-checked: #ffffff;


    /* Reusable Interface Measurements */
    --element-gap: 1px;
    --ui-width-uiWindow: 342px;
    --ui-width-colorpicker: 244px;
    --ui-width-uiWidnow-numeric: 342;
    --ui-padding-default-numeric: 15;
    --ui-padding-uiWindow: 15px;
    --ui-padding-default-horizontal: 12px;
    --ui-padding-default-vertical: 7.5px;
    --ui-button-border-radius: 100px;
    --ui-padding-default: var(--ui-padding-default-vertical) var(--ui-padding-default-horizontal);
    --ui-lineHeight-default: var(--lineHeight-default);
    --ui-window-border-background: rgba(var(--baseColor-default-reverse-rgb), .2);

    /* Dark Mode Helpers */
    --ui-details-background: #d1d1d1;
    --ui-close-circle-opacity: .25;

    /* Mobile */
    --ui-maxWidth-mobile: 95vw;

    /* Outline */
    --outline-uiWindow-default: var(--element-gap) solid var(--baseColor-border);

    /* Window Border Radius */
    // --window-outerRadius: 4px;
    // --window-innerRadius: 3px;

    /* subWindow Border Radius ( color picker, font picker, context menu ) */
    // --subWindow-outerRadius: 7px;
    // --subWindow-innerRadius: 6px;

    // --window-boxShadow-default: 3px 3px 41px rgba(0, 0, 0, 0.14);
    // --window-boxShadow-default: 4px 4px 23px rgba(0, 0, 0, 0.20);
    --window-boxShadow-default: 2px 2px 19px rgba(0, 0, 0, 0.12);
    // --window-boxShadow-heavy: 0px 2px 50px rgba(0, 0, 0, 0.15), 2px 2px 25px rgba(0, 0, 0, 0.25);
    --window-boxShadow-heavy: 4px 4px 40px rgba(0, 0, 0, 0.25);

    /* Opacity */
    --opacity-downstate-default: .7;
    --opacity-icon-default: .75;


    /* User Interface — starts at the at the 'row' level */
    --ui-background-default: var(--baseColor-default);
    --ui-background-overlayer: var(--baseColor-overlayer);
    --ui-background-overlayer-active: var(--baseColor-overlayer-active);
    --ui-background-confirm: var(--baseColor-confirm);
    --ui-background-accent: var(--baseColor-accent);
    --ui-background-container: var(--baseColor-container);
    --ui-background-accent-reverse: var(--baseColor-accent-reverse);
    --ui-background-subLayer: var(--baseColor-subLayer);

    --ui-color-default: var(--baseColor-default-reverse);
    --ui-color-accent: var(--baseColor-accent);
    --ui-color-accent-reverse: var(--baseColor-accent-reverse);
    --ui-color-confirm: var(--baseColor-confirm);
    --ui-color-error: #ff0000;
    --ui-color-default-soft: var(--baseColor-default-reverse-soft);
    --ui-color-default-softer: var(--baseColor-default-reverse-softer);

    --ui-background-bars: var(--baseColor-default);

    // dark mode needs to avoid 100% white/black
    --ui-color-flat: rgb(var(--baseColor-default-rgb));
    --ui-color-flat-reverse: rgb(var(--baseColor-default-reverse-rgb));

    --ui-list-block-active: #f9f9f9;

    --ui-checkers-bg: rgb(var(--baseColor-default-rgb));
    --ui-arrow-opacity: .4;

	--ui-noFiles-fill: #CECECE;
	--ui-files-background: #F2F2F2;
	--ui-fileName-background: #F1F1F1;
	--ui-fileType-outline: 1px solid #DDDDDD;
    --ui-svg-shade: #242424;

    --ui-load-spinner-large-speed: 1.2s;
    --ui-load-spinner-large-background-radius: 24px;
    // --ui-load-spinner-large-background-color: rgba(120,120,120,.75);
    --ui-load-spinner-large-background-color: rgba(120,115,115,.75);

    --ui-hint-background: #B7C9FF;
    --ui-message-background: #FFE600;

    /* border radius */
    --border-outer: 11px;
    --border-inner: calc( var(--border-outer ) - 1px );

    /* z-index layers */ 

    --layer-0: 10;
    --layer-1: 100;
    --layer-2: 200;
    --layer-3: 300;
    --layer-4: 400;
    --layer-5: 500;
    --layer-6: 600;
    --layer-7: 700;
    --layer-8: 800;
    --layer-9: 900;
    --layer-10: 1000;


    font-family: var(--fontFamily-default), 'Icons';
    font-size: var(--fontSize-default);
    line-height: var(--lineHeight-default);
    // letter-spacing: 0;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga"on;

    text-align: left;
}

.dark {
    // exclude Design Lab
    &:not(.designlab) {
        // --ui-background-default: var(--baseColor-default);
        // --ui-window-border-background: #a5a5a5ee;
        --ui-window-border-background: rgb(var(--darkBorder-rgb));
        --ui-details-background: #444444;
        // dark mode gets alpha-channel values
        --ui-color-flat: rgba(var(--baseColor-default-rgb), .85);
        --ui-color-flat-reverse: rgba(var(--baseColor-default-reverse-rgb), .85);

        // --window-boxShadow-default: 0px 0px 0px 1px rgba(127, 127, 127, .18);

        --ui-background-bars: var(--baseColor-container);

        --ui-checkers-bg: #1A1A1A;
        --ui-arrow-opacity: .47;

        --ui-noFiles-fill: #676767;
        --ui-files-background: #0A0A0A;
        --ui-fileName-background: #0C0C0C;
        --ui-fileType-outline: 1px solid #4A4A4A;
        --ui-svg-shade: #CCCCCC;

        --ui-hint-background: #504C8F;
        --ui-message-background: #00B46E;

        --ui-list-block-active: var(--ui-background-container);

        --ui-close-circle-opacity: .615;
    }
}