@use '@cargo/common/css/global-base-styles' as globalBaseStyles;

body .alert-window {
    --alert-baseColor-confirm: var(--baseColor-default-reverse);
}
// admin alert colors
body #admin-app .alert-window,
body #app .alert-window {
    --alert-baseColor-confirm: var(--baseColor-accent);
}

.alert-window {

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,.4);
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

    /* transition values */
    --x1: 0.260;
    --y1: 0.270;
    --x2: 0.380;
    --y2: 0.925;
    
    --enter: cubic-bezier(var(--x1), var(--y1), var(--x2), var(--y2));
    
    /* Reversed transition values */
    --x1-r: calc(1 - var(--x2));
    --y1-r: calc(1 - var(--y2));
    --x2-r: calc(1 - var(--x1));
    --y2-r: calc(1 - var(--y1));
    
    --exit: cubic-bezier(var(--x1-r), var(--y1-r), var(--x2-r), var(--y2-r));
    
    opacity: 0;
    transition: opacity 0.28s var(--enter); // --exit

    &.active {
        opacity: 1;
        transition: opacity 0.28s var(--enter);
    }

    &.over-cart {
        z-index: 99999;
    }

    button {
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

}

.alert-window.active .alert-modal {
    transform: translateY(0);
    opacity: 1;
    transition: opacity 0.28s var(--enter), transform 0.28s var(--enter);
}

.alert-modal {
    background: var(--ui-background-default);
    border-radius: 5px;
    padding: var(--ui-padding-uiWindow);
    padding-top: calc(var(--ui-padding-uiWindow) * 2);
    width: 430px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
    position: relative;
    transform-origin: bottom center;
    transform: translateY(-15px);
    transition: opacity 0.28s var(--enter), transform 0.28s var(--enter); //exit

    &:after {
        content: '';
        box-shadow: inset 0px 0px 0px 1px rgba(var(--baseColor-default-reverse-rgb), .06);
        position: absolute;
        top: -1px; right: -1px; bottom: -1px; left: -1px;
        pointer-events: none;
        border-radius: 5px;
    }

    .form {
        width: 100%;
        padding-right: calc(var(--ui-padding-uiWindow) * .5);

        select {
            width: 100%;
            font-size: 16px;
        }
    }

    .message {
        flex: 1;
        font-size: 16px;

        .header {
            display: inline-block;
            margin-bottom: 1em;
            width: 100%;
            // font-weight: bold;
            color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
            // fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
            font-size: var(--fontSize-header);
            line-height: 1;
            margin-bottom: 10px;
            display: inline-block;
            font-weight: 600;
        }

        .alert-action-link {
            border-bottom: 1px solid rgba(var(--baseColor-default-reverse-rgb), 0.85);
            color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
            cursor: pointer;
            display: inline-block;

            .underlined,
            &:active .underlined {
                // border-bottom: 1px solid rgba(var(--baseColor-default-reverse-rgb), 0.85);
                text-decoration: underline;
                border-bottom: none;
            }

            &:active {
                opacity: var(--opacity-downstate-default);
            }
        }

        .sub-message {
            display: inline-block;
            width: 100%;
            margin-bottom: 1em;
            font-weight: normal;
            line-height: 1.3;
            text-align: left;
            color: var(--ui-color-flat-reverse);
        }
    }

    .opt-buttons {
        padding: calc(var(--ui-padding-uiWindow) * 2) 0 0;
        display: flex;
        align-self: center; 
    }

    .input {
        display: flex;
        flex: 1 1;
        justify-content: center;
        align-items: center;
        margin-top: -6px;

        input {
            &[type="text"] {
                outline: 1px solid rgba(var(--baseColor-accent-rgb), .4);
                font-family: var(--fontFamily-default);
                font-size: var(--fontSize-default);
                line-height: calc(var( --ui-lineHeight-default ) - .1);
                color: var(--ui-color-default);
                background: var(--ui-background-default);
                border: none;
                width: 84%;
                height: 100%;
                margin: 0;
                -webkit-font-smoothing: antialiased;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-top: calc(var(--ui-padding-default-vertical) - 1px);
                padding-bottom: calc(var(--ui-padding-default-vertical) + 1px);
                padding-left: calc(var(--ui-padding-default-horizontal) - 2px);
                padding-right: var(--ui-padding-default-horizontal);
                margin: 0 1px;
            }
        }
    }
    

    .buttons {
        // padding: calc(var(--ui-padding-uiWindow) * 2) 0 0;
        display: flex;
        align-self: center; 

        button.rounded {

            appearance: none;
            -webkit-appearance: none;
            border: none;
            cursor: pointer;
            font-size: var(--fontSize-default);
            font-family: var(--fontFamily-default);
            padding: 0;
            text-align: left;
            white-space: nowrap;
            background: transparent;
            // must be flex by default
            display: flex;
            margin: 0;

            justify-content: flex-start;
            width: 110px;

            flex: 1;
            padding: 8px;
            height: auto;
            line-height: 1;
            border-radius: var(--ui-button-border-radius);
            font-weight: 500;
            padding: 6.5px var(--ui-padding-default-horizontal) 7.5px var(--ui-padding-default-horizontal);

            &.deny {
                background: rgba(var(--baseColor-default-reverse-rgb), 0.1);
                color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
                fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
            }

            &.confirm,
            &.notice {
                background: var(--alert-baseColor-confirm);
                color: var(--ui-color-accent-reverse);
            }

        }

        button.confirm {
            position: relative;
        }

        button.rounded + button.rounded {
            margin-left: 5px;
        }

        button:active {
            opacity: var(--opacity-downstate-default);
            color: inherit;
        }
    }

    .buttons + .footer {
        margin-top: 15px;
    }

    .footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;


        .checkbox {
            user-select: none;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            display: inline-block;
            @include globalBaseStyles.ui-color-default(.6);
            padding-left: 26px;
            transform: translate(-5px, 2px);

            & input[type="checkbox"] {
                height: 0;
                width: 0;
                visibility: hidden;
            }

            & input:after {
                // @include no-select;
                // background-color: var(--ui-background-subLayer);
                width: var(--diameter-checkbox-circle);
                height: var(--diameter-checkbox-circle);
                // border-radius: 100%;
                content: '';
                cursor: pointer;
                pointer-events: none;
                position: absolute;
                visibility: visible;
                opacity: .8;
                /* Positioning is centered based on row height and indicator diameter */
                // top: calc(var(--height-row) / 2 - var(--diameter-checkbox-circle) / 2);
                // right: calc(var(--height-row) / 2 - var(--diameter-checkbox-circle) / 2);
                top: 0;
                left: 0;
                // background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.6288 1.40874L4.93846 13.2401L0.435547 8.09388L1.56441 7.10612L4.7615 10.7599L11.3711 0.591255L12.6288 1.40874Z' fill='white'/%3E%3C/svg%3E%0A");
                // background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.15' fill-rule='evenodd' clip-rule='evenodd' d='M14.741 1.00543L7.43846 12.24L2.93555 7.09387L4.06441 6.10611L7.2615 9.75993L13.4955 0.169128C13.184 0.0595819 12.849 0 12.5 0H3C1.34315 0 0 1.34315 0 3V12.5C0 14.1569 1.34315 15.5 3 15.5H12.5C14.1569 15.5 15.5 14.1569 15.5 12.5V3C15.5 2.23426 15.2131 1.53553 14.741 1.00543Z' fill='black'/%3E%3C/svg%3E%0A");
                background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.4' d='M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H12C13.3807 0.5 14.5 1.61929 14.5 3V12C14.5 13.3807 13.3807 14.5 12 14.5H3C1.61929 14.5 0.5 13.3807 0.5 12V3Z' stroke='black'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: 80% 65%;
            }

            & input:checked:after {
                opacity: 1;
                // background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.741 1.00543L7.43846 12.24L2.93555 7.09387L4.06441 6.10611L7.2615 9.75993L13.4955 0.169128C13.184 0.0595819 12.849 0 12.5 0H3C1.34315 0 0 1.34315 0 3V12.5C0 14.1569 1.34315 15.5 3 15.5H12.5C14.1569 15.5 15.5 14.1569 15.5 12.5V3C15.5 2.23426 15.2131 1.53553 14.741 1.00543Z' fill='%23FF0000'/%3E%3C/svg%3E%0A");
                background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 0C1.34314 0 0 1.34314 0 3V12C0 13.6569 1.34314 15 3 15H12C13.6569 15 15 13.6569 15 12V3C15 1.34314 13.6569 0 12 0H3ZM7.44257 11.5463C7.25537 11.8398 6.97424 12 6.63025 12C6.4632 12 6.30945 11.9622 6.16705 11.8799C6.02838 11.7999 5.91418 11.6855 5.81238 11.5527L5.81158 11.5516L3.22034 8.13782C3.08759 7.96338 3 7.75897 3 7.54858C3 7.07635 3.3504 6.73004 3.81696 6.73004C3.96063 6.73004 4.09656 6.75952 4.22406 6.83325C4.34766 6.90479 4.44525 7.00745 4.53082 7.12439L6.58521 9.93481L10.7323 3.18188C10.8124 3.05255 10.9067 2.93567 11.0362 2.85663C11.1699 2.7749 11.3127 2.75 11.4584 2.75C11.6793 2.75 11.8831 2.82544 12.0316 2.97742C12.1793 3.12866 12.25 3.33276 12.25 3.54932C12.25 3.74487 12.1921 3.92975 12.0621 4.13202L7.44257 11.5463Z' fill='%23FF0000'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &.publish,
    &.dialog,
    &.confirm,
    &.notice,
    &.slide-confirm {
        border-radius: var(--windowRadiusLarge-inner);
        min-height: 210px;
        padding: calc(var(--ui-padding-uiWindow) * 1.5) var(--ui-padding-uiWindow) var(--ui-padding-uiWindow) calc(var(--ui-padding-uiWindow) * 1.5);

        &:after {
            border-radius: var(--windowRadiusLarge);
        }

        .message {
            flex: 0;
            line-height: 0;
            white-space: pre-wrap;     
            white-space: -moz-pre-wrap;
            white-space: -pre-wrap;    
            white-space: -o-pre-wrap;  
            word-wrap: break-word;     

            .header {
                line-height: 1.3;
                margin-bottom: .2em;
                text-align: left;
            }

            select {
                width: 100%;
            }
        }

        .buttons {
            display: flex;
            align-self: center;
            flex: 0 1;
            align-items: flex-end;
            justify-content: flex-end;
            width: 100%;
            margin-top: auto;

            button {
                flex: 0 1 auto;
                min-width: 110px;
                width: auto;

                &.rounded {
                    height: auto;
                    line-height: 1;
                    border-radius: var(--ui-button-border-radius) !important;
                    font-weight: 500;
                    padding: 6.5px var(--ui-padding-default-horizontal) 7.5px var(--ui-padding-default-horizontal);
                }

            }
        }
    }

    &.slide-confirm {
        padding: 0;

        .message {
            padding: 22px 20px;
            padding-bottom: 10px;
            flex: 1 1;
            font-size: 17px;
            // padding: 26px 20px 22px;
            line-height: 1.3em;
            display: flex;
            align-items: center;
            flex-direction: column;

            .header {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 1em;
                font-weight: 700;
                line-height: 1.3;
            }

        }

        .buttons {
            padding: 0 15px 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: auto;

            .deny {
                color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
                margin-bottom: 20px;
                font-size: 17px;
                line-height: 1.2;
                border-bottom: 1px solid rgba(var(--baseColor-default-reverse-rgb), 0.85);
                padding: 0;
                border-radius: 0;
                background: transparent;
                min-width: auto;
                width: auto;
                flex: 0;

                &:active {
                    color: inherit;
                    opacity: .6;
                }
            }
        }
    }

    &.publish {
        .buttons {
            flex: 1;
        }
    }

    &.dialog {
        .message {
            .header {
                margin-bottom: 0;
            }
        }
        .input {
            padding-right: calc(calc(var(--ui-padding-uiWindow) * 1.5) - var(--ui-padding-uiWindow));
        }
    }
}

.dark {
    .alert-modal {
        &:after {
            box-shadow: inset 0px 0px 0px 1px var(--ui-window-border-background);
            border-radius: var(--windowRadiusLarge-inner);
            inset: 0px;
        }

        .footer {
            .checkbox {

                & input:after {
                    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.47' d='M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H12C13.3807 0.5 14.5 1.61929 14.5 3V12C14.5 13.3807 13.3807 14.5 12 14.5H3C1.61929 14.5 0.5 13.3807 0.5 12V3Z' stroke='rgba(255, 255, 255, .85)'/%3E%3C/svg%3E%0A");
                }

                & input:checked:after {
                    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 0C1.34314 0 0 1.34314 0 3V12C0 13.6569 1.34314 15 3 15H12C13.6569 15 15 13.6569 15 12V3C15 1.34314 13.6569 0 12 0H3ZM7.44257 11.5463C7.25537 11.8398 6.97424 12 6.63025 12C6.4632 12 6.30945 11.9622 6.16705 11.8799C6.02838 11.7999 5.91418 11.6855 5.81238 11.5527L5.81158 11.5516L3.22034 8.13782C3.08759 7.96338 3 7.75897 3 7.54858C3 7.07635 3.3504 6.73004 3.81696 6.73004C3.96063 6.73004 4.09656 6.75952 4.22406 6.83325C4.34766 6.90479 4.44525 7.00745 4.53082 7.12439L6.58521 9.93481L10.7323 3.18188C10.8124 3.05255 10.9067 2.93567 11.0362 2.85663C11.1699 2.7749 11.3127 2.75 11.4584 2.75C11.6793 2.75 11.8831 2.82544 12.0316 2.97742C12.1793 3.12866 12.25 3.33276 12.25 3.54932C12.25 3.74487 12.1921 3.92975 12.0621 4.13202L7.44257 11.5463Z' fill='%23FFC600'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }
}

.mobile {
    .alert-modal {
        max-width: var(--ui-maxWidth-mobile);
    }
}