@use '@cargo/common/css/global-base-styles' as globalBaseStyles;

#domain-search {

	.input-icon-group {
		grid-gap: 0;
	}

	.search-button {
		button {
			svg {
				height: 20px !important;
				color:  rgba(var(--baseColor-default-reverse-rgb), .3);
			}
			&:active {
				opacity: 1;

				svg {
					color: rgba(var(--baseColor-default-reverse-rgb), .25)

				}
			}
		}
    	
    }

    .search-button {
        background: var(--baseColor-confirm);
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            position: relative;
            height: 20px;
            width: 20px;
        }
    }

    .search-results {
        display: grid;
        grid-gap: 1px;

        .single-domain {
            display: grid;
            grid-template-columns: 40px 1fr 64px;
           
            background: var(--baseColor-default);
            height: var(--height-row);

            // .domain-text {
            //     display: grid;
            //     grid-auto-columns: auto;
            //     grid-template-columns: auto 1fr;
            //     grid-gap: 0;
            
            //     .url {
            //         max-width: 25ch;
            //         text-overflow: ellipsis;
            //         overflow: hidden;
            //         white-space: nowrap;
            //     }
            // }

            &.available {
                cursor: pointer;
                &:active {
                    opacity: 0.75;
                }
            }

            &.unavailable {
                cursor: default;
                color: #999999;
            }

            .domain-text {
                text-align: left;
                padding: var(--ui-padding-default);
                padding-left: 0 !important;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .availability-text {
                text-align: right;
                padding: var(--ui-padding-default);
            }

            .status {
                position: relative;
                height: var(--height-row);
                width: var(--height-row);
            }
            .available {
                float: left;
                height: 8px;
                width: 8px;
                border-radius: 4px;
                position: absolute;
                top: calc(50% - 4px);
                left: calc(50% - 4px);

                &.no {
                    background-color: #f30;
                }
                &.yes {
                    background-color: var(--baseColor-confirm);
                }
            }

            .pending {
                animation: pending 2s linear infinite;
                -moz-animation: pending 2s linear infinite; /* Firefox */
                -webkit-animation: pending 2s linear infinite; /* Safari and Chrome */
                -o-animation: pending 2s linear infinite; /* Opera */
            }

            @keyframes pending {
                0% {background-color:#ccc;}
                50% {background-color:#000;}
                100% {background-color:#ccc;}
            }

            @-moz-keyframes pending {
                0% {background-color:#ccc;}
                50% {background-color:#000;}
                100% {background-color:#ccc;}
            }

            @-webkit-keyframes pending {
                0% {background-color:#ccc;}
                50% {background-color:#000;}
                100% {background-color:#ccc;}
            }

            @-o-keyframes pending {
                0% {background-color:#ccc;}
                50% {background-color:#000;}
                100% {background-color:#ccc;}
            }

        }
    }

    .more-actions {
        button.show-more {
            @include globalBaseStyles.ui-color-default(.85);
        }
    }
}


[window-name="account-manager"] .uiWindow-content #domain-search {
	margin-bottom: calc( var(--ui-padding-uiWindow) * 2);
}

[window-name="subscription-window"] .uiWindow-content .domains {

    .domain-card {

        position: relative;
        display: grid;
        grid-auto-flow: row;
        grid-auto-rows: auto;
        grid-gap: var(--element-gap);
        max-width: calc( var(--domain-manager-width) - calc( var(--ui-padding-default-horizontal) * 2 ) );
        

        .domain-details {
            background: var(--baseColor-default);
            padding: var(--ui-padding-default);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            grid-auto-flow: row;
            height: auto;
        }
        
        .in-use-by-placeholder {
            display: block;
            color:#999999; 
            visibility: hidden;
            pointer-events: none;
            user-select: none;
        }

        .domain-name {
            display: block;
        }

        .domain-in-use {
            display: block;
            color:#999999; 
            position: absolute;
            z-index: 2;
            top: 32px;
            left: 12px;

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 345px;
            
            .in-use-link {
                text-decoration: none;
                color: rgba(var(--baseColor-default-reverse-rgb), 0.4);

                &:hover {
                    text-decoration: underline;
                    text-decoration-color: rgba(var(--baseColor-default-reverse-rgb),.3);
                }
                
                &:active {
                    opacity: var(--opacity-downstate-default);
                }

                
            }

        }

        .loader {
            align-self: center;
            opacity: 0.6;
        }

        // .use-domain,
        // .use-subdomain {
        //     color: var(--baseColor-accent);
        // }

        .save-subdomain {
            font-weight: 700;
            color: var(--baseColor-default);
            background: var(--baseColor-confirm);
            svg {
                color: var(--baseColor-default);
                fill: var(--baseColor-default);
                height: 17px;
                width: auto;
                position: absolute;
                top: 12px;
                right: 9px;
            }
        }

        .text-input.autosize .input-auto-size input {
            max-width: 329px;
        }

        .text-input.autosize .input-auto-size {
            max-width: 367px;
        }

    }

    .subdomains {
        > .ui-group {
            grid-gap: 10px;

            .text-input.autosize .input-auto-size span {
                color: rgba(var(--baseColor-default), .85)
            }
        }

    }
}