/* Light Color Scheme */
body {
    --baseColor-accent: #FF0000;
    --baseColor-accent-rgb: 255, 0, 0;
    --baseColor-accent-reverse-rgb: 255, 255, 255;

    --baseColor-accent-mobile: #007AFF;
    --baseColor-accent-mobile-rgb: 0, 122, 255;
    --baseColor-accent-reverse: #ffffff;

    --baseColor-accent-alt1: #FFFFC8;
    --baseColor-accent-alt1-rgb: 255, 255, 200;

    --baseColor-container: #efeeec;
    --baseColor-overlayer: #f7f7f7;
    --baseColor-overlayer-active: #EAEAEA;

    --baseColor-border: #DBDCE0;
    --baseColor-confirm: #0FCE83;
    --baseColor-alert: #FF0000;

    --baseColor-default: #ffffff;
    --baseColor-default-rgb: 255,255,255;
    --baseColor-default-reverse-rgb: 0,0,0;
    --baseColor-default-reverse: rgba(var(--baseColor-default-reverse-rgb), .85);
    --baseColor-default-reverse-soft: rgba(var(--baseColor-default-reverse-rgb), .6);
    --baseColor-default-reverse-softer: rgba(var(--baseColor-default-reverse-rgb), .4);

    --legacyColor-grayscale: #f2f2f2;
    --legacyColor-grayscale-darker: #ccc;

    // range input background, 
    --baseColor-subLayer: rgba(var(--baseColor-default-reverse-rgb), .05);
}

body.dark,
#editor-overlay.dark {
    // exclude Design Lab
    &:not(.designlab) {

        --baseColor-accent: #FFC600;
        --baseColor-accent-rgb: 255, 198, 0;
        --baseColor-accent-reverse-rgb: 32, 32, 32;
        --baseColor-accent-reverse: #202020;
        // --baseColor-accent-alt1: #5C4033;
        // --baseColor-accent-alt1-rgb: 92, 64, 51;
        --baseColor-default: #202020;
        --baseColor-container: #2d2d2d;
        --baseColor-border: #474747;
        --baseColor-subLayer: #cccccc;
        --baseColor-confirm: #0FCE83;

        --baseColor-default-rgb: 0, 0, 0;
        --baseColor-default-reverse-rgb: 255,255,255;
        --baseColor-default-reverse: rgba(var(--baseColor-default-reverse-rgb), .85);
        --baseColor-default-reverse-soft: rgba(var(--baseColor-default-reverse-rgb), .6);
        --baseColor-default-reverse-softer: rgba(var(--baseColor-default-reverse-rgb), .4);

        --darkBorder-rgb: 65, 65, 65;
        --darkShadow: rgb(41 41 42 / 75%);

        --legacyColor-grayscale: #f2f2f2;
        --legacyColor-grayscale-darker: rgba(var(--baseColor-default-reverse-rgb),.1);
    }
}