.loader {
    line-height: 0;
    pointer-events: none;
    border-radius: 100%;

    &.padding-window {
        padding: var(--ui-padding-uiWindow);
    }

    &.hidden {
        display: none;
    }

    & > div {
        border-radius: 100%;
        width: 20px;
        height: 20px;
        -webkit-animation: spin-loading 12s ease-out;
        -webkit-animation-iteration-count: infinite;
        -moz-animation: spin-loading 12s ease-out;
        -moz-animation-iteration-count: infinite;
        animation: spin-loading 12s ease-out;
        animation-iteration-count: infinite;

        svg {
            max-width: 100%;
            height: auto;
        }
    }

    &.center {
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50%);
        transform: translate(-50%, -50%);
    }

    &.flex-center {
        display: inline-flex;
        align-self: center;
        justify-self: center;
    }

    &.dark svg > * {
        opacity: 0.6;
        color: var(--ui-color-flat-reverse);
        fill: var(--ui-color-flat-reverse);
    }

    &.light svg > * {
        opacity: 0.25;
        color: var(--ui-color-flat-reverse);
        fill: var(--ui-color-flat-reverse);
    }

    &.linear > div {
        fill: var(--ui-color-flat);
        transform-origin: center;
        -webkit-animation: linear-spin 1s linear;
        -webkit-animation-iteration-count: infinite;
        -moz-animation: linear-spin 1s linear;
        -moz-animation-iteration-count: infinite;
        animation: linear-spin 1s linear;
        animation-iteration-count: infinite;

    }

    &.fade-in {
        opacity: 0;
        animation-delay: .1s;
        -webkit-animation-delay: .1s;
        -moz-animation-delay: .1s;
        animation-duration: 12s;
        animation-iteration-count: infinite;
        animation: fade-in .8s ease-in-out;
        -moz-animation: fade-in .8s ease-in-out;
        -webkit-animation: fade-in .8s ease-in-out;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }

    &.linear > div {
        transform-origin: center;
        -webkit-animation: linear-spin var(--ui-load-spinner-large-speed) linear infinite;
        -moz-animation: linear-spin var(--ui-load-spinner-large-speed) linear infinite;
        animation: linear-spin var(--ui-load-spinner-large-speed) linear infinite;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        &.delay {
            animation-delay: .8;
            -webkit-animation-delay: .5;
            -moz-animation-delay: .5;
        }
        
    }

    &.reverse > div {
        animation-direction: reverse;
    }

    *.red > div {
        fill: red !important;
    }
}

@-webkit-keyframes spin-loading {
    0%      { transform: rotate(0deg); }
    9%      { transform: rotate(1050deg); }
    18%     { transform: rotate(-1090deg); }
    20%     { transform: rotate(-1080deg); }
    23%     { transform: rotate(-1080deg); }
    28%     { transform: rotate(-1095deg); }
    29%     { transform: rotate(-1065deg); }
    34%     { transform: rotate(-1080deg); }
    35%     { transform: rotate(-1050deg); }
    40%     { transform: rotate(-1065deg); }
    41%     { transform: rotate(-1035deg); }
    44%     { transform: rotate(-1035deg); }
    47%     { transform: rotate(-2160deg); }
    50%     { transform: rotate(-2160deg); }
    56%     { transform: rotate(45deg); }
    60%     { transform: rotate(45deg); }
    80%     { transform: rotate(6120deg); }
    100%    { transform: rotate(0deg); }
}

/*spinning */
@-webkit-keyframes spin-loading {
    0%      { transform: rotate(0deg); }
    9%      { transform: rotate(1050deg); }
    18%     { transform: rotate(-1090deg); }
    20%     { transform: rotate(-1080deg); }
    23%     { transform: rotate(-1080deg); }
    28%     { transform: rotate(-1095deg); }
    29%     { transform: rotate(-1065deg); }
    34%     { transform: rotate(-1080deg); }
    35%     { transform: rotate(-1050deg); }
    40%     { transform: rotate(-1065deg); }
    41%     { transform: rotate(-1035deg); }
    44%     { transform: rotate(-1035deg); }
    47%     { transform: rotate(-2160deg); }
    50%     { transform: rotate(-2160deg); }
    56%     { transform: rotate(45deg); }
    60%     { transform: rotate(45deg); }
    80%     { transform: rotate(6120deg); }
    100%    { transform: rotate(0deg); }
}

/* linear spin */
@keyframes linear-spin {
    0%   { transform: rotate(0deg);   }
    10%  { transform: rotate(36deg);  }
    20%  { transform: rotate(72deg);  }
    30%  { transform: rotate(108deg); }
    40%  { transform: rotate(144deg); }
    50%  { transform: rotate(180deg); }
    60%  { transform: rotate(216deg); }
    70%  { transform: rotate(252deg); }
    80%  { transform: rotate(288deg); }
    90%  { transform: rotate(324deg); }
    100% { transform: rotate(360deg); }
}

@keyframes delay {
    0%      {opacity: 0;}
    99%     {opacity: 0;}
    100%    {opacity: 1.0;}
}

// .loading_animation {
//     display: none;
//     vertical-align: middle;
//     z-index: 15;
//     line-height: 0;
//     pointer-events: none;
//     display: inline-block;
// }

// .loading_animation.hidden {
//     display: none
// }

// .loading_animation div svg {
//     max-width: 100%;
//     height: auto
// }

// .loading_animation div,
// .loading_animation div svg {
//     width: 60px;
//     height: 60px
// }

// .loading_animation.full-width svg {
//     width: 100%;
//     height: auto
// }

// .loading_animation.full-width.big svg {
//     width: 100px;
//     height: 100px
// }

// .loading_animation div svg > * {
//     fill: #ccc;
//     transform-origin: center;
//     -webkit-animation: spin-loading 12s ease-out;
//     -webkit-animation-iteration-count: infinite;
//     -moz-animation: spin-loading 12s ease-out;
//     -moz-animation-iteration-count: infinite;
//     animation: spin-loading 12s ease-out;
//     animation-iteration-count: infinite
// }

// .loading_animation.hidden {
//     display: none
// }

// .loading_animation.position-absolute.middle {
//     top: calc(50% - 10px);
//     left: calc(50% - 10px)
// }

// .loading_animation.position-absolute.topleft {
//     top: 0;
//     left: 0
// }

// .loading_animation.position-absolute.middleright {
//     top: calc(50% - 10px);
//     right: 1rem
// }

// .loading_animation.position-absolute.middleleft {
//     top: calc(50% - 10px);
//     left: 1rem
// }

// .loading_animation.inline {
//     display: inline-block;
//     margin-bottom: .5ex
// }




