@use './windows';

#collaborators {
	--ui-collaborator-diameter: 24px;

	background: var(--ui-background-default);
	display: flex;
    flex-direction: column;
    align-items: center;
    padding: calc(calc(var(--height-row) - var(--ui-collaborator-diameter)) / 2);

	.collaborator {
		width: var(--ui-collaborator-diameter);
		height: var(--ui-collaborator-diameter);
		border-radius: 100%;
		background: red;
		box-shadow: inset 0px 0px 0px 1px var(--ui-color-accent-reverse);
	}

	.collaborator + .collaborator {
		margin-top: -8px;
	}
}

.uiWindow[window-name="editors"] {

	@include windows.windowRadius-large;

    font-family: var(--fontFamily-default);
    font-size: var(--fontSize-default);
    position: fixed;

    .processing {
    	height: 154.5px;
    	// padding: var(--ui-padding-uiWindow);
    }

    form .ui-group {
    	max-height: 300px;
    	overflow: scroll;
    }

	box-shadow: 4px 4px 30px rgb(0 0 0 / 15%);
	
	// top: auto;
	// bottom: 15px;

	window-header {
		margin-left: 0;
		margin-right: 0;

		window-label {
			margin-bottom: 25px;
		}
	}

	.user {
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: auto;

		max-width: 310px;

		&.error input {
			color: #f00;
		}
		
		&:not(.editable) {
			grid-template-columns: auto 1fr auto;
			text-align: left;
		    line-height: var(--ui-lineHeight-default);
		    background-color: var(--ui-background-default);
		    height: var(--height-row);

		    &.pending {
		    	.email-printed,
		    	.invited {
		    		color: rgba(var(--baseColor-default-reverse-rgb), 0.5);
		    		fill: rgba(var(--baseColor-default-reverse-rgb), 0.5);
		    	}
		    }

		    

		    .email-printed {
				color: var(--ui-color-flat-reverse);
		    	padding: var(--ui-padding-default);
		    	padding-left: 0;
		    	padding-right: 15px;
		    	overflow: hidden;
		    	text-overflow: ellipsis;
		    	white-space: nowrap;
		    	min-width: 202px;
		    }

		    .invited {
		    	height: var(--height-row);
		    	padding: var(--ui-padding-default);
		    	padding-left: 0;
		    	display: flex;
		    	align-self:center;

		    }
		}

		&.editable {
			grid-template-columns: 1fr auto;

			.user-role-button {
				width: 64px;
				padding: var(--ui-padding-default);
				padding-left: 6px;

				&.disabled {
					opacity: 1 !important;
					pointer-events: none;
					text-decoration: none;
				}


				&.disabled::active {
					opacity: 1 !important;
				}

			}

		}

		button {
			color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
			fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
			text-decoration: underline;

			&:active {
				opacity: 1;
				color: rgba(var(--baseColor-default-reverse-rgb), 0.2);
				fill: rgba(var(--baseColor-default-reverse-rgb), 0.2);
			}

		}

		.disabled {
			// opacity: 0.7 !important;
			pointer-events: none !important;
			-webkit-touch-callout: none !important;
			-webkit-user-select: none !important;
			-khtml-user-select: none !important;
			-moz-user-select: none !important;
			-ms-user-select: none !important;
			user-select: none !important;
		}
	}

	button {
		&.user-role-button {
			padding-left:0;
			text-align: right;
			&.disabled {
				opacity: 1 !important;
				text-decoration: none;
				pointer-events: none;
			}
			&.disabled::active {
				opacity: 1 !important;
			}
		}
	}

	.more-actions button[type="submit"].disabled {
		background: rgba(var(--baseColor-default-reverse-rgb), 0.25);
	}

	button[type="submit"] {
		position: relative;
		.caution-processing {
			border-radius: var(--ui-button-border-radius);
		}
	}

	.ui-group .text-input.autosize .input-auto-size {
		max-width: 237.77px;
	}

	.ui-group .text-input.autosize .input-auto-size::after, 
	.ui-group .text-input.autosize .input-auto-size input {
		max-width: 225px;
	}

	.ui-group .text-input.autosize.no-label .input-auto-size {
		max-width: 304.77px;
	}

	.ui-group .text-input.autosize.no-label .input-auto-size::after, 
	.ui-group .text-input.autosize.no-label .input-auto-size input {
		max-width: 292px;
	}


}

.mobile {

	.uiWindow[window-name=editors] {

		width: 580px;
		
		.user {
			max-width: unset;

			.user:not(.editable) {
				.email-printed {
					min-width: unset;
				}
			}

			.ui-group {
				justify-content: stretch;
			}
		}

		window-content {
			.user-role-button {
				width: calc(64px + 1ch);
			}
		}
	}
}