
.uiWindow[window-name="subscription-info-sticky"][type="popover"]{
	min-width: 315px;
	transform: none !important;
	top: 11vh !important;
	// left: calc((100vw + 350px) / 2) !important;
	left: calc(50vw - 315px - 235px) !important;
	// right: calc(25vw - 243px);

	--sticky-bg: #fef49c;
}

.uiWindow[window-name="subscription-info-sticky"][type="popover"]{
	--windowRadiusSmall: 12px;
	--windowRadiusSmall-inner: calc(var(--windowRadiusSmall) - 1px);

	// width: 24rem; rem sizing not consistent across environments
	width: 293px;
	transform: none;
	box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.15);

	.sp {
		max-width: 2em;
		height: 2em;
		width: 2em;
		float: left;
		margin-right: 0.5em;
		transform: translate(0, 0.05em);
	}

	.message-support {
		cursor: pointer;
		text-decoration: underline;
	}

	&[position=center] {
		bottom: 3rem;
	    left: auto;
	    top: auto;
	    right: 10rem;
	    position: fixed;
	}

	.close.close-uiWindow {
		position: absolute;
		right: 10px;
		top: 10px;
		display: none;
	}

	.uiWindow-content {
		font-family: var(--fontFamily-default);
		font-size: var(--fontSize-default);
	}

	.uiWindow-content .uiWindow-inner {

		background: var(--sticky-bg);
		padding: 15px;
		// padding-right: 25px;
		color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
		font-size: var(--fontSize-default);
		// font-size: 16px;
		font-weight: 400;
		// font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
		font-family: "CargoDiatypeVariable";
		letter-spacing: 0;

		.linked {
			text-decoration: underline;
			color: inherit;

			&:active {
				opacity: .7;
			}
		}

		b {
			font-weight: 800;
		}

		.title {
			display: flex;
			font-size: 26px;
			font-weight:500;
			line-height: 1.1;
			font-style: italic;


			&.large {
				font-weight: 700;
				color: #D16044;

				.emojicheck {
					font-style: normal;
					margin-right: .2em;
				}
			}
		}

		.legend {
			display: block;
			line-height: 1.3;
			margin-left: 20px;

			.legend-item{
				display: flex;
				align-items: center;
				margin-top: 0.1em;

				.icon {
					    position: relative;
					    display: inline-flex;
					    margin-right: 0.1em;
					}
				}
			}

	}


}

.dark {
	.uiWindow[window-name="subscription-info-sticky"][type="popover"] {
		--sticky-bg: var(--ui-hint-background);

		.uiWindow-content {
			background: none;
		}
	}
}